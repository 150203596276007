<template>
  <div>
    <headbox title="关于我们"></headbox>
    <div class="backgroudColor">
      <!-- <div class="cue">
        <h2>关于我们</h2>
    </div> -->
      <div class="profile">
        <h1>关于{{GLOBAL}}</h1>
        <p>
          {{GLOBAL}}创建于2016年,是由中共中央党校(国家行政学院)、中信集团、华帝计算机集团共同投资的全资子公司,旗下{{GLOBAL}}APP是一个专注线上贵金属购销的平台.
        </p>
        <!-- <a href="#" class="gold_vlog">
            <img src="../assets/images/gold_img.png">
        </a> -->      
        <video v-if="videoUrl" poster="../assets/images/gold_img.png" controls class="videoPlayer">
          <source :src="videoUrl" type="video/mp4" />
        </video>
        <!-- <vue-core-video-player 
                :autoplay="false"
                :src="videoSource"
                ref='player'
            >
            </vue-core-video-player> -->
        <!-- <video-player  class="video-player vjs-custom-skin"
         ref="videoPlayer"
         :playsinline="true"
         :options="playerOptions"
        ></video-player>  -->
      </div>
      <div class="profile">
        <h1>选择理由</h1>
        <p>为什么大家卖金都选{{GLOBAL}}？</p>
        <div>
          <a href="#" class="superiority">
            <img class="sore" src="../assets/images/pic_yewu.png" />
          </a>
        </div>
      </div>
      <div class="profile">
        <h1>检测中心</h1>
        <p>送货到店或线上寄件任您选择</p>
        <!-- <div class="scrollView"  v-for="testingCenter in testingCenter"> -->
        <div class="scrollView">
          <img v-if="!src1" class="store_photo0"  src='../assets/images/tianyaCenter.png'/>
          <img v-else class="store_photo0"  :src='src1'/>
          <img v-if="!src2" class="store_photo1"  src='../assets/images/shuibeiCenter.png' v-on=""/>
          <img v-else class="store_photo1" :src="src2" />
        </div>
      </div>
      <!-- <div class="footnote">
      <a href="#">
        <div class="telNum">
          客服电话
        </div>
      </a>
      <a href="#">
        <div class="download">
          下载{{GLOBAL}}APP
        </div>
      </a>
      <span></span>
    </div> -->
    </div>
    <div class="footnote">
      <a href="#" @click="callPhone">
        <div class="telNum">
          4001-886-722
        </div>
      </a>
      <a href="https://beijingzxj.com/download.html">
        <div class="download">
          下载{{GLOBAL}}APP
        </div>
      </a>
      <span></span>
    </div>
  </div>
</template>
<script>
import headbox from "@/components/head.vue";
import { about } from "@/utils/api";
import { videoPlayer } from "vue-video-player";
import SZstore from "@/assets/images/SZstore.jpg";
// import tvVedio from "@/assets/aboutUs.mp4";
import "video.js/dist/video-js.css";

export default {
  components: {
    videoPlayer,
    headbox
  },
  data() {
    return {
      mantle: true,
      testingCenters: [],
      src1: "",
      src2: "",
      beijingImage: "",
      shenzhenImage: "",
      // videoUrl: tvVedio,
      videoUrl: "",
      videoSource: [],
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        // fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [
          {
            src: this.videoUrl, // 路径
            type: "video/mp4" // 类型
          }
        ],

        poster: SZstore, //你的封面地址
        // width: document.documentElement.clientWidth,
        notSupportedMessage: "此视频暂无法播放，请稍后再试", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true //全屏按钮
        }
      }
    };
  },
  mounted() {
    const that = this;
    about().then(res => {
      const datalist = res.video;
      that.testingCenters = res.photo;
      console.log("================" + this.testingCenters);
      that.src1 = that.testingCenters[0].url;
      that.src2 = that.testingCenters[1].url;
      for (const b in datalist) {
        this.videoUrl = datalist[b].url;
        this.videoSource = [
          {
            src: this.videoUrl,
            type: "video/mpeg"
          }
        ];
        // console.log(this.$refs.player);
        this.playerOptions["sources"][0]["src"] = this.videoUrl;
      }
    });
  },
  methods: {
    callPhone() {
      window.location.href = 'tel:// 4001-886-722'
    }
  },
  
};
</script>
<style scoped>
/* *{
        padding: 0;
        margin: 0;
    } */
.backgroudColor {
  width: 100%;
  background-color: #e6e6e6;
  padding-top: 8px;
  /* margin-bottom: 100px; */
  /* bottom-top: 300px; */
}
.cue {
  font-size: 34px;
  text-align: center;
  height: 128px;
  padding-top: 60px;
}

h1 {
  font-size: 30px;
  height: 33px;
  padding-top: 0px;
  padding-left: 15px;
}
.backgroudColor:after {
  display: block;
  content: "";
  height: 200px;
}

.profile {
  width: 710px;
  padding: 20px;
  /* height: 720px; */
  margin: 26px 20px 26px 20px;
  background-color: white;
}
.profile p {
  padding-top: 8px;
  padding-left: 15px;
  padding-right: 10px;
  font-size: 26px;
  line-height: 5.2vmin;
  color: #333232;
}
.gold_vlog img {
  width: 690px;
  height: 380px;
  padding-left: 15px;
  padding-right: 15px;
}
.superiority img {
  /* width: 91vmin;
        height: 41vmin; */
  padding-left: 15px;
  padding-right: 15px;
}
.scrollView {
  width: 100%;
  /* height: 200px; */
  white-space: nowrap;
  overflow-x: auto;
}
.store_photo0 {
  margin-top: 18px;
  width: 450px;
  /* height: 270px; */
  /* height: 310px;  */
  /* padding-left: 10px; */
  /* padding-right: 10px; */
  border-radius: 10px;
  /* background-color: brown; */
  /* margin: auto; */
  /* display:flex; */
  /* vertical-align: middle; */
}

.store_photo1 {
  width: 450px;
  /* height: 270px; */
  /* height: 310px;  */
  margin-left: 10px;
  /* padding-right: 10px; */
  border-radius: 10px;
  /* background-color: brown; */
  /* margin: auto; */
  /* display:flex; */
  /* vertical-align: middle; */
}

.sore {
  padding-top: 20px;
  padding-left: 30px;
}
.backgroudColor > div {
  border-radius: 8px;
}
.footnote {
  background-color: #ffffff;
  /* height: 120px; */
  /* margin-bottom: 0px; */
  position: fixed;
  bottom: 0;
  width: 100%;
  /* background-color: brown; */
}
.footnote a div {
  font-family: PingFang SC;
  font-size: 24px;
  display: inline-block;
  width: 320px;
  height: 85px;
  border: 1px solid #777777;
  border-radius: 12px;
  /* background-color: chocolate; */
}
.telNum {
  color: #292929;
  text-align: center;
  line-height: 72px;
  margin: 24px 70px 30px 24px;
  background-image: url("../assets/images/dianhua1.png");
  background-repeat: no-repeat;
  background-position: 15px 15px;
  background-size: 45px;
  margin-left: 20px;
}
.download {
  color: #fefefe;
  background-color: #080808;
  text-align: center;
  line-height: 75px;
}
.video-player {
  height: 300px;
  width: 100%;
  background-color: cornflowerblue;
}
.vjs-custom-skin {
  height: 300px;
  width: 100%;
  background-color: cornflowerblue;
}
.videoPlayer {
  margin-top: 10px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 18px;
}
video {
  width: 100%;
}
</style>
