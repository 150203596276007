var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", { attrs: { title: "关于我们" } }),
      _c("div", { staticClass: "backgroudColor" }, [
        _c("div", { staticClass: "profile" }, [
          _c("h1", [_vm._v("关于" + _vm._s(_vm.GLOBAL))]),
          _c("p", [
            _vm._v(
              " " +
                _vm._s(_vm.GLOBAL) +
                "创建于2016年,是由中共中央党校(国家行政学院)、中信集团、华帝计算机集团共同投资的全资子公司,旗下" +
                _vm._s(_vm.GLOBAL) +
                "APP是一个专注线上贵金属购销的平台. "
            )
          ]),
          _vm.videoUrl
            ? _c(
                "video",
                {
                  staticClass: "videoPlayer",
                  attrs: {
                    poster: require("../assets/images/gold_img.png"),
                    controls: ""
                  }
                },
                [
                  _c("source", {
                    attrs: { src: _vm.videoUrl, type: "video/mp4" }
                  })
                ]
              )
            : _vm._e()
        ]),
        _c("div", { staticClass: "profile" }, [
          _c("h1", [_vm._v("选择理由")]),
          _c("p", [_vm._v("为什么大家卖金都选" + _vm._s(_vm.GLOBAL) + "？")]),
          _vm._m(0)
        ]),
        _c("div", { staticClass: "profile" }, [
          _c("h1", [_vm._v("检测中心")]),
          _c("p", [_vm._v("送货到店或线上寄件任您选择")]),
          _c("div", { staticClass: "scrollView" }, [
            !_vm.src1
              ? _c("img", {
                  staticClass: "store_photo0",
                  attrs: { src: require("../assets/images/tianyaCenter.png") }
                })
              : _c("img", {
                  staticClass: "store_photo0",
                  attrs: { src: _vm.src1 }
                }),
            !_vm.src2
              ? _c(
                  "img",
                  _vm._g({
                    staticClass: "store_photo1",
                    attrs: {
                      src: require("../assets/images/shuibeiCenter.png")
                    }
                  })
                )
              : _c("img", {
                  staticClass: "store_photo1",
                  attrs: { src: _vm.src2 }
                })
          ])
        ])
      ]),
      _c("div", { staticClass: "footnote" }, [
        _c("a", { attrs: { href: "#" }, on: { click: _vm.callPhone } }, [
          _c("div", { staticClass: "telNum" }, [_vm._v(" 4001-886-722 ")])
        ]),
        _c("a", { attrs: { href: "https://beijingzxj.com/download.html" } }, [
          _c("div", { staticClass: "download" }, [
            _vm._v(" 下载" + _vm._s(_vm.GLOBAL) + "APP ")
          ])
        ]),
        _c("span")
      ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("a", { staticClass: "superiority", attrs: { href: "#" } }, [
        _c("img", {
          staticClass: "sore",
          attrs: { src: require("../assets/images/pic_yewu.png") }
        })
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }